import { useContext, useEffect, useState } from '../../_snowpack/pkg/react.js';
import config from '../app-config.js';
import { UserContext } from './user-context.js';
import { getActiveProductSubId, getActiveProductSubName } from '../views/settings/pricing-plans/create-crystallize-subscription/utils.js';

async function getProductSubscriptions(customerIdentifier) {
  const query = `
    query GET_ALL_SUBSCRIPTIONS($customerIdentifier: String!){
      subscriptions {
        getAll(customerIdentifier: $customerIdentifier)
      }
    }
  `;
  const response = await fetch(config.CRYSTALLIZE_SHOP_GRAPHQL_SERVICE_API, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      query,
      variables: {
        customerIdentifier: customerIdentifier
      }
    })
  });
  const json = await response.json();

  if (json.errors) {
    throw new Error(JSON.stringify(json, null, 1));
  }

  return json.data.subscriptions.getAll;
}

export function useTenantPlan() {
  const [status, setStatus] = useState('loading');
  const [planName, setPlanName] = useState('');
  const [hasActivated, setHasActivated] = useState(false);
  const {
    tenant
  } = useContext(UserContext);
  useEffect(() => {
    (async function get() {
      const result = await getProductSubscriptions(tenant.identifier);
      setPlanName(getActiveProductSubName(result));
      setHasActivated(getActiveProductSubId(result) ? true : false);
      setStatus('loaded');
    })();
  }, [tenant.identifier]);
  return {
    status,
    planName,
    hasActivated
  };
}